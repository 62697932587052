import { render, staticRenderFns } from "./tips-publish.vue?vue&type=template&id=2154d282&scoped=true&"
import script from "./tips-publish.vue?vue&type=script&lang=js&"
export * from "./tips-publish.vue?vue&type=script&lang=js&"
import style0 from "./tips-publish.vue?vue&type=style&index=0&lang=css&"
import style1 from "./tips-publish.vue?vue&type=style&index=1&id=2154d282&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2154d282",
  null
  
)

export default component.exports