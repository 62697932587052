<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  /deep/.annouce-group .ck-editor__editable {
    min-height: 405px !important;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  import {
    tipsDetail,
    saveTips,
    editTips
  } from "@/api/web/tips.js";


  /**
   * 公共信息编辑
   */
  export default {
    components: {
      Layout,
      PageHeader,



    },
    data() {
      return {
        title: "推送信息 ",
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "控制面板",
            active: true
          }
        ],
        editor: ClassicEditor,
        tid: "",
        addForm:{
          tid:"",
          bt:"",
          nr:"",
          zjhm:null,
        }
      };
    },
    mounted() {
      this.tid = this.$route.params.tid
      if (this.tid){
        this.getTipsDetail(this.tid);
      }
    },
    methods: {
      /** 获取规章制度详情 */
      getTipsDetail(tid){
        tipsDetail(tid).then(res => {
          if (res.status) {
            this.addForm = res.data
          }
        });
      },
      /** 保存规章制度 */
      submitForm() {
        // 校验数据
        if (this.checkForm()) {
          // 提交
          let params = this.addForm
          if(this.tid){
            editTips(params).then(res => {
              if (res.status) {
                this.$message({
                  type: 'success',
                  message: '提交成功!'
                });
              }
              this.goToListPage()
            })
          }else{
            saveTips(params).then(res => {
              if (res.status) {
                this.$message({
                  type: 'success',
                  message: '保存成功!'
                });
              }
              this.goToListPage()
            })
          }
        }
      },
      goToListPage(){
        this.$router.push({
          path: '/admin/tips',
          name: 'tips'
        })
      },

      /** 校验规章制度参数 */
      checkForm() {
        if (this.addForm.bt == "") {
          this.checkAlert("标题不能为空")
          return false;
        }
        if (this.addForm.nr == "") {
          this.checkAlert("内容不能为空")
          return false;
        }
        return true;
      },
      /** 校验提醒 */
      checkAlert(message) {
        this.$message({
          type: 'error',
          message: message
        });
      },
    },
    created() {
      this.setUEditorConfig() // 初始化百度编辑器
      this.UEditorConfig.initialFrameHeight = 400; //定义富文本高度
    },
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <form class="w-100  announce-form">
              <div class="annouce-group flexList">
                <div class="announce-name">推送标题：</div>
                <input type="text" name="commonName" v-model="addForm.bt" class="form-control annouce-input" value="">
                <span class="font-size-12 ml-1">尽量简洁，50字以内</span>
              </div>
              <div class="annouce-group flexList" style="height: 500px;">
                <div class="announce-name">推送内容：</div>
                <div class="ml-2 w-75 h-100 pt-2 pb-2" style="overflow-y: auto;">
                  <vue-ueditor-wrap v-model="addForm.nr" :config="UEditorConfig" ></vue-ueditor-wrap>
                </div>
              </div>
               <div class="m-2 text-center ">
                 <button type="button" class="btn btn-info h30 w-sm mr-2" @click="submitForm">{{tid==null?'提交':'保存'}}
                 </button>
                <button type="button" class="btn btn-secondary h30 w-sm " @click="goToListPage">取消返回
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->


    <!-- 弹窗结束 -->
  </Layout>
</template>
